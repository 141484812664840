/*
  Hero variables
*/
$hero-pad:4em;

/*
  Hero mixins
*/
@mixin hero-background($image){
  background:url($image) no-repeat bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/*
  Hero styles
*/
.hero{
  padding:$hero-pad 0;
  text-align:center;
  @include hero-background("../images/bg.jpg");
  @include breakpoint(small down){
    padding: $hero-pad/2 0;
  }
  .subheader{
    margin-bottom:2em;
  }
}