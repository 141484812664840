.callout.hls-primary{
  background:$hls-primary-callout-background;
  color:$hls-primary-callout-text;
  border:none;

  h4{
    color:$hls-primary-callout-text;
  }
  a{
    color:$hls-primary-callout-link;
  }
}