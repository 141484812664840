/*
Button variables

*/
$facebook:#3B5998;
$google:#D50F25;
$twitter:#6CADDE;

/*
Button mixins

*/
@mixin button-color($color){
  background:lighten($color, 5%);

  &:hover{
    background:$color;
  }
  &:focus,
  &:active{
    background:darken($color, 10%);
  }
}
/*
  Button styles

*/
.button{
  background:$button-background-color;
  color:$button-text-color !important;
  @include transition;
  &:hover{
    background:$button-background-color-hover;
  }
}

/*
  Social media button

*/
.facebook{
  @include button-color($facebook);
}
.googlePlus{
  @include button-color($google);
}
.twitter{
  @include button-color($twitter);
}

/*
  Top-bar button

*/
.top-bar-button{
  margin:0;

  @include breakpoint(medium down){
    display:block;
    width:49%;
    margin-right:1%;
    margin-bottom:1%;
    float:left;
  }
}

