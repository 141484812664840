/*
  Global-colors...obviously.
*/

/*
  Theme Colors
*/
$primary-color:orange;

/*
  Default colors
*/
$body-p-color:$black;
$body-h-color:rgba($black, 0.8);

/*
  Header colors
*/
$header-background-color:$primary-color;
$header-link-color:darken($primary-color, 30%);

/*
  Hero colors
*/
$hero-background-color:darken($primary-color, 30%);



/*
  Footer colors
*/
$footer-background-color: $primary-color;
$footer-text-color:darken($primary-color, 30%);
$footer-link-color:darken($primary-color, 30%);

/*
  Button colors
*/
$button-background-color:$primary-color;
$button-background-color-hover:darken($button-background-color, 10%);
$button-text-color:$white;

/*
  Callout colors
*/
$hls-primary-callout-background:$primary-color;
$hls-primary-callout-text:darken($primary-color, 30%);
$hls-primary-callout-link:$white;