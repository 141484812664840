/*
  Menu variables
*/


/*
  Menu mixins
*/


/*
  Menu styles
*/
.site-menu-wrapper{
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  @include breakpoint(medium down){
    display:block;
    ul{
      padding:0;
      li{
        float:left;
        width:100%;
        text-align:center;
      }
    }
  }
}