/*
  Sidebar variables
*/

/*
  Sidebar mixins
*/

/*
  Sidebar styles
*/
.sidebar{
  img{
    border:8px solid #526D00;
  }
}