/*
  Header variables
*/
$font-size:2.0em;

/*
  Header mixins
*/

/*
  Header styles
*/
.top-bar li > a, .top-bar ul li > a {
    color:$header-link-color;
    font-weight:bold;
}

.top-bar{
  background-color:$header-background-color;
  @include shadow-1;
  .row{
    display:flex;
    flex-direction:row;
    padding:0;
    @include breakpoint(medium down){
      flex-direction:column;
    }
  }
  .header-links{
    display:flex;
    flex-direction:column;
    justify-content:center;
    
    .button {
        color: $header-link-color !important;
        font-weight:bold;
        @include breakpoint(medium down){
          width:100%;
        }
    }
  }
  ul{
    background-color:inherit;

  }
}

