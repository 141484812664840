/*
  Page:Index variables
*/

/*
  Page:Index mixins
*/

/*
  Page:Index styles
*/
.home-row{
  padding-bottom:0;
  .medium-6 img{
    border:8px solid #526D00;
  }
}
.main-section{
  .row{
    padding:0;
  }
  img{
    border:8px solid #526D00;
    margin-bottom:1em;
  }
}
