/*
 Typography Variables
*/

/*
 Typography Mixins
*/

/*
 Typography Styles
*/
body{
  color:$body-p-color;
  @include font-stack-body;
}
h1,h2,h3,h4,h5{
  color:$body-h-color;
  @include font-stack-header;
}
header{

}

.subheader{
  font-size:1.5em;
}
