/*
 Sizing Variables
*/
$pad:3rem;

/*
  Sizing Mixins
*/

/*
 Sizing Styles
*/
.row{
  padding:$pad 0;

}
ul{
  margin:0;
  padding-left:25px;

}
.main-section{
  ul{
    margin-bottom:1em;
  }
}